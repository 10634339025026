import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Tag from "./tag"
import normalizeTag from "../utils/normalize_tag"
import PostDate from "./post-date"

const ItemImage = styled.div`
  width: 35%;
`

const Excerpt = styled.p`
  color: ${props => props.theme.grey300};
`

export default function PostCard(props) {
  return (
    <div className="d-flex">
      <ItemImage>
        <Link to={'/' + props.slug}>
          <Img fluid={props.key_image.childImageSharp.fluid} />
        </Link>
      </ItemImage>
      <div className="d-flex flex-column flex-grow-1 ml-4">
        <div className="d-flex justify-content-between align-items-center">
          <ul className="d-flex pl-0">
            {props.tags.map((tag) => (
              <li key={tag} className="mr-2"><Tag><Link to={'/' + normalizeTag(tag)}>{tag}</Link></Tag></li>
            ))}
          </ul>
          <PostDate size="sm">{props.date}</PostDate>
        </div>
        <Link to={'/' + props.slug}>
          <h2 className="mt-1 mb-2">{props.title}</h2>
          <Excerpt>{props.excerpt}</Excerpt>
        </Link>
      </div>
    </div>
  )
}
